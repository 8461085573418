import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import title_image from '../images/titles/technology_consulting.jpg'
import person from '../images/person.png'
import ContactText from '../components/ContactText'

const pageTitle = "Technology Services";
const pageSlug = "technology-services";
class BusinessConsulting extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">We can plan, build, and deliver technology solutions to solve any of your business challenges.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    The impact of technology on business behavior and strategy is continuing to grow.
                    We believe that technology can significantly boost sales, enhance customer service, 
                    and streamline all business practices.
                    </h3>
                   <p>A full range of technological services await.</p>
                   <p>Nuerex's services include all types of custom software development, leading-edge technologies, 
                       strategy, and enterprise application support. Our experts take the worry out of developing, 
                       planning, sustaining and keeping up with innovative and new technologies — their experts at 
                       guiding clients through this process.</p>
                    <span className="break"></span>
                    <h3 className="intext">Business is never isolated!</h3>
                    <span className="break"></span>
                    <p>Constant digital and technological innovations are empowering business transformation; growing 
                        and evolving requires adapting to new services or designing your own. With our expertise and 
                        experience, we can help you do both.</p>
                    <Link to="/digital-consulting-services" className="simple-link">DISCOVER OUR DIGITAL SERVICES</Link>
                    <Link to="/business-consulting" className="simple-link">DISCOVER OUR BUSINESS CONSULTING SERVICES</Link>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>

      <section className="section static-page page-services">
          <div className="container">
              <div className="page-title-area">
                  <h2 className="title">Technology Services</h2>
                  <h6 className="subtitle">We can plan and build a solution that's perfect for you. Here is how:</h6>
              </div>
              <div className="columns is-multiline">
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/data-and-analytics"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Data and Analytics</h2>
                        <p className="desc">We can help deliver professional and verified insight to your business.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/modern-software-delivery"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Modern Software Delivery</h2>
                        <p className="desc">A Modern Software Delivery strategy allows you to realize the value 
                        in your technological investments while being competitive in a disruptive marketplace.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/enterprise-collaboration"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Enterprise Collaboration</h2>
                        <p className="desc">Collaborating with you every day to connect your ideas, people, 
                        and capabilities. Our experts will help your employees work better together.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/mobile-app-development"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Mobile App Development</h2>
                        <p className="desc">We're passionate about helping our clients transform 
                        and change their work with mobile app development technology.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/it-strategy"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">IT Strategy</h2>
                        <p className="desc">We understand how to transform IT capabilities into engines of business value.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/devops"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">DevOps</h2>
                        <p className="desc">Helping you beat your competition by delivering value faster by 
                        automating the software delivery to production processe.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/outsourcing"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Outsourcing</h2>
                        <p className="desc">We can provide you with an innovative delivery for long-term management, 
                        enhancement, and maintenance of custom or packaged applications.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/enterprise-applications-and-solutions"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Enterprise Applications and Solutions</h2>
                        <p className="desc">By combining strategic insights with hands-on Enterprise Resource 
                        Planning (ERP) and Customer Relationship Management (CRM) resources, we can find 
                        your solution.</p>
                      </Link>
                  </div>
                  <div className="single column is-3">
                      <Link to={"/"+pageSlug+"/cloud-computing"} className="inner">
                          <span className="line"></span>
                        <h2 className="title">Cloud Computing</h2>
                        <p className="desc">Together we can offer you a wide array of 
                        computing solutions that can improve and transform your business processes.</p>
                      </Link>
                  </div>
              </div>
          </div>
      </section>

     <ContactText />

      </Layout>
    )
  }
}

export default BusinessConsulting
